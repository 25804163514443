import { lazy } from "react";

export const MyAccountProfileDetailsLazy = lazy(() =>
  import("../../core/Dashboard/Common/MyAccountProfileDetails.js")
);

export const RequestLogsLazy = lazy(() =>
  import("../../core/Dashboard/Common/RequestLogs/RequestLogs.js")
);

export const ShippingSettingsLazy = lazy(() =>
  import("../../core/Dashboard/Common/ShippingSettings/ShippingSettings.js")
);

export const ApiSettingsLazy = lazy(() =>
  import("../../core/Dashboard/Common/ApiSettings.js")
);

export const ActiveSubscriptionsLazy = lazy(() =>
  import("../../core/Dashboard/Common/ActiveSubscriptions.js")
);

export const BillingHistoryLazy = lazy(() =>
  import("../../core/Dashboard/Common/BillingHistory.js")
);

export const BrandingSettingsLazy = lazy(() =>
  import("../../core/Dashboard/Common/Branding/BrandingSettings.js")
);

export const ShipmentRecordsLazy = lazy(() =>
  import("../../core/Dashboard/Common/ShipmentRecords/ShipmentRecords.js")
);

export const CheckoutPageLazy = lazy(() =>
  import("../../payments/CheckoutForm.js")
);

export const UpdatePaymentMethodLazy = lazy(() =>
  import("../../payments/UpdatePaymentMethod.js")
);
export const UserAnalyticsDashboardLazy = lazy(() =>
  import("../../core/Dashboard/Common/AnalyticsDashboard/AnalyticsDashboard.js")
);
