import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Fab, Typography, Grid } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    // Patch fix.
    if (["request-logs"].includes(window.location.pathname)) {
      window.location.reload();
    }
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={10}>
            <Typography align="center" variant="h5">
              Error | Something went wrong!
            </Typography>
            <div className="newline2" />
            <Typography align="center">
              <Fab
                variant="extended"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <HomeIcon />
                &nbsp;&nbsp;Home
              </Fab>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
