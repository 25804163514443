import { useContext } from "react";
import {
  SignupLazy,
  SigninLazy,
  ForgotLazy,
  ResetLazy,
  // ShippingCalculatorFormLazy,
  DisplayLabelsLazy,
  PricingPageLazy,
} from "../lazy-imports/publicRouteImports.js";
import { sessionContext } from "../../App.js";
import { isEmpty } from "../../utils/helperMethods.js";

export const publicRoutesArray = [
  {
    path: "/",
    Component: () => {
      // Get user session details.
      const sess = useContext(sessionContext);
      const [session] = sess;
      // If session is active push to dashboard.
      // If session inactive, push to /signup.
      window.location.href = !isEmpty(session)
        ? "/analytics-dashboard"
        : "/signup";
      return null;
    },
  },
  {
    path: "/signup",
    Component: SignupLazy,
  },
  {
    path: "/signin",
    Component: SigninLazy,
  },
  {
    path: "/auth/password/forgot",
    Component: ForgotLazy,
  },
  {
    path: "/auth/password/reset/:token",
    Component: ResetLazy,
  },
  {
    path: "/display-shipping-label",
    Component: DisplayLabelsLazy,
  },
  {
    path: "/pricing",
    Component: PricingPageLazy,
  },
];
