import {
  SubscriptionsListLazy,
  UsersListLazy,
  InvoiceSettingsLazy,
  // GuestUserSettingsLazy,
  UserActionsLazy,
  AnalyticsDashboardLazy,
  AllOrders,
  // Test purposes only.
  // TestHarnessLazy,
} from "../lazy-imports/adminRouteImports.js";

export const adminRoutesArray = [
  {
    path: "/admin/list-subscriptions-offered",
    Component: SubscriptionsListLazy,
  },
  {
    path: "/admin/users",
    Component: UsersListLazy,
  },
  {
    path: "/admin/all-orders",
    Component: AllOrders,
  },
  {
    path: "/admin/invoice-settings",
    Component: InvoiceSettingsLazy,
  },
  // {
  //   path: "/admin/guest-user-settings",
  //   Component: GuestUserSettingsLazy,
  // },
  {
    path: "/admin/user-actions",
    Component: UserActionsLazy,
  },
  {
    path: "/admin/analytics-dashboard",
    Component: AnalyticsDashboardLazy,
  },
  // {
  //   path: "/admin/test-harness",
  //   // Test purposes only.
  //   Component: TestHarnessLazy,
  // },
];
