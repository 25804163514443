export const env = {
  api_url: "https://api.reachship.com",
  google_oauth_client_id:
    "235861852874-otiof023m5uh987rstqc5r850songm51.apps.googleusercontent.com",
  facebook_app_id: "309847897166501",
  recaptcha_site_key: "6LeUw6IaAAAAABUDqc6ly4W6vi04bX5tjmmglrUY",
  google_maps_api_key: "AIzaSyAkvSZs6KZt9ApMEUYsZ16pBrhK2nyDTng",
  is_staging: false,
  stripe_public_key: "pk_live_cNul6oU93S3AYefC9FCKpBby",
  maintenance_mode: "off",
};
