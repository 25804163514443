import React, { useState } from "react";
import { Snackbar, Button } from "@mui/material";
import Cookies from "js-cookie";

const CookieConsentModal = () => {
  const [open, setOpen] = useState(true);

  return Cookies.get("rs-cookie-consent") === undefined ? (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      message={
        "By using our site, you acknowledge that you have read and understood our Privacy Policy, and our Terms of Service."
      }
      action={
        <React.Fragment>
          <Button
            size="small"
            href="https://reachship.com/privacy-policy/"
            target="_blank"
            style={{ color: "#4D8EF5", margin: "auto 5px" }}
          >
            Privacy Policy
          </Button>
          <Button
            size="small"
            href="https://reachship.com/terms-conditions/"
            target="_blank"
            style={{ color: "#4D8EF5", margin: "auto 5px" }}
          >
            Terms of Service
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              setOpen(false);
              Cookies.set("rs-cookie-consent", true, { expires: 30 });
            }}
          >
            I AGREE
          </Button>
        </React.Fragment>
      }
    />
  ) : null;
};

export default CookieConsentModal;
