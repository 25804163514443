import { lazy } from "react";

export const UpdatePaymentMethodLazy = lazy(() =>
  import("../../payments/UpdatePaymentMethod.js")
);
export const UsersListLazy = lazy(() =>
  import("../../core/Dashboard/Admin/UserMenuPages/UsersList.js")
);
export const SubscriptionsListLazy = lazy(() =>
  import(
    "../../core/Dashboard/Admin/SubscriptionMenuPages/SubscriptionsList.js"
  )
);
export const AllOrders = lazy(() =>
  import("../../core/Dashboard/Admin/OrderMenuPages/AllOrders.js")
);
export const InvoiceSettingsLazy = lazy(() =>
  import("../../core/Dashboard/Admin/SettingsMenuPages/InvoiceSettings.js")
);
export const GuestUserSettingsLazy = lazy(() =>
  import("../../core/Dashboard/Admin/SettingsMenuPages/GuestUserSettings.js")
);
export const UserActionsLazy = lazy(() =>
  import("../../core/Dashboard/Admin/SettingsMenuPages/UserActions.js")
);
export const AnalyticsDashboardLazy = lazy(() =>
  import("../../core/Dashboard/Admin/SettingsMenuPages/AnalyticsDashboard.js")
);
// Test purposes only.
// export const TestHarnessLazy = lazy(() =>
//   import("../../core/Dashboard/Admin/SettingsMenuPages/TestHarness.js")
// );
