import React from "react";
import not_found from "../assets/illustrations/not_found.svg";
import { Fab, Typography, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const PageNotFound = () => {
  document.title = "Page not found - ReachShip";
  if (document.querySelector("meta[name='description']")) {
    document.querySelector("meta[name='description']").remove();
  }

  return (
    <div className="reachship-dashboard-background">
      <Grid container justifyContent="center">
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <Typography align="center" variant="h5" sx={{ mt: 2 }}>
            404 Error | Page Not Found!
          </Typography>

          <img
            src={not_found}
            width="100%"
            height="100%"
            alt="Page Not Found"
          />

          <Typography align="center">
            <Fab
              variant="extended"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <HomeIcon sx={{ mr: 1 }} />
              Home
            </Fab>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageNotFound;
