import React from "react";
import { Typography, Grid } from "@mui/material";
import reachship_nav from "../assets/images/reachship_logo_transparent_dark.svg";

const Maintenance = () => {
  document.title = "Site under maintenance - ReachShip";
  if (document.querySelector("meta[name='description']")) {
    document.querySelector("meta[name='description']").remove();
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10}>
        <Typography align="center" variant="h5">
          <img
            src={reachship_nav}
            alt="reachship_navbar_logo"
            style={{ height: "30px", width: "100px", paddingTop: "5px" }}
          />
        </Typography>
        <Typography align="center" variant="h5">
          ReachShip is currently down for maintenance.
        </Typography>
        <Typography align="center" variant="p">
          We expect to be back in a couple of hours. Thanks for your patience.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Maintenance;
