import React, { createContext, useState, useEffect } from "react";

// Material UI.
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import axios from "axios";
import { IdleTimeoutManager } from "idle-timer-manager";

import CookiesDisabledError from "./error/CookiesDisabledError.js";
import Routes from "./routes/Routes.js";
import Maintenance from "./error/Maintenance.js";
import { env } from "./env";
import ErrorBoundary from "./error/ErrorBoundary.js";
import Loader from "./utils/Loader.js";
import CookieConsentModal from "./utils/CookieConsentModal.js";
import { signout } from "./auth/helpers.js";
import { isEmpty } from "./utils/helperMethods.js";
import "./App.css";

export const sessionContext = createContext(null);

const App = () => {
  // Prevent bots from crawling staging site.
  if (
    env.is_staging === true &&
    isEmpty(localStorage.getItem("permit_staging"))
  ) {
    let password;
    while (isEmpty(password)) {
      password = prompt("Please enter your password");
      if (password !== "RS123") {
        password = null;
      } else {
        localStorage.setItem("permit_staging", true);
      }
    }
  }
  const [session, setSession] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [sitewideNotificationMessage, setSitewideNotificationMessage] =
    useState(null);

  let theme = createTheme({
    fontFamily: "Arial, sans-serif",
    palette: {
      primary: {
        main: "#011f4b",
      },
      secondary: {
        main: "#03396c",
      },
      default: {
        main: "#fff",
      },
      danger: {
        main: "red",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
      },
    },
    customBadge: {
      backgroundColor: "#00AFD7",
      color: "white",
    },
    zIndex: {
      modal: 9999,
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundColor: "#F7B844",
            color: "black",
            fontWeight: 400,
            "&:hover": {
              backgroundColor: "#F7B844",
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
    },
  });
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    axios({
      withCredentials: true,
      method: "GET",
      url: `${env.api_url}/auth/isauth`,
    })
      .then((response) => {
        setSession(response.data);
        axios({
          withCredentials: true,
          method: "GET",
          url: `${env.api_url}/user/retrieve-sitewide-notification`,
        })
          .then((response) => {
            setSitewideNotificationMessage(response.data);
          })
          .catch(() => {});
        setApiCalled(true);
      })
      .catch((error) => {
        setSession(null);
        setApiCalled(true);
      });
  }, []);

  // Check for idle sessions.
  useEffect(() => {
    if (!isEmpty(session)) {
      // don't check if user unauthorized.
      const manager = new IdleTimeoutManager({
        timeout: 60 * 60, // Expires after 60 minutes.
        onExpired: async (time) => {
          const deleteSession = await signout();
          if (deleteSession === "success") {
            setSession(null);
            window.location.href = "/signin";
          }
        },
      });
      return () => {
        manager.clear();
      };
    }
  }, [session]);

  if (env.maintenance_mode === "on") {
    return <Maintenance />;
  }

  return navigator.cookieEnabled ? (
    apiCalled ? (
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <sessionContext.Provider
            value={[
              session,
              setSession,
              sitewideNotificationMessage,
              setSitewideNotificationMessage,
            ]}
          >
            <Routes />
            <CookieConsentModal />
          </sessionContext.Provider>
        </ErrorBoundary>
      </ThemeProvider>
    ) : (
      <Loader />
    )
  ) : (
    <CookiesDisabledError />
  );
};

export default App;
