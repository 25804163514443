// Library Files.
import React, { Suspense, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { sessionContext } from "../App.js";
import { isEmpty } from "../utils/helperMethods.js";

//Loader
import Loader from "../utils/Loader.js";

// Routes.
import { publicRoutesArray } from "./routes-array/publicRoutesArray.js";
import { adminRoutesArray } from "./routes-array/adminRoutesArray.js";
import { userRoutesArray } from "./routes-array/userRoutesArray.js";
import PageNotFound from "../error/PageNotFound.js";

// User Deactivated Page.
import DeactivatedUser from "../core/DeactivatedUser.js";

const Routes = () => {
  const sess = useContext(sessionContext);
  const [session] = sess;

  const publicRouteComponents = publicRoutesArray.map(
    ({ path, Component }, key) => {
      return (
        <Route
          exact
          path={path}
          key={key}
          onEnter={() => window.location.reload()}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <Component {...props} />
            </Suspense>
          )}
        />
      );
    }
  );

  const adminRouteComponents = adminRoutesArray.map(
    ({ path, Component }, key) => {
      return (
        <Route
          exact
          path={path}
          key={key}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              {!isEmpty(session) &&
              !isEmpty(session.markedAccountForDeletion) &&
              session.markedAccountForDeletion.isMarked &&
              session.markedAccountForDeletion.markedOn ? (
                <DeactivatedUser />
              ) : !isEmpty(session) && session.role === "admin" ? (
                <Component {...props} />
              ) : (
                <Redirect to="/signin" />
              )}
            </Suspense>
          )}
        />
      );
    }
  );

  const userRouteComponents = userRoutesArray.map(
    ({ path, Component }, key) => {
      return (
        <Route
          exact
          path={path}
          key={key}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              {!isEmpty(session) &&
              !isEmpty(session.markedAccountForDeletion) &&
              session.markedAccountForDeletion.isMarked &&
              session.markedAccountForDeletion.markedOn ? (
                <DeactivatedUser />
              ) : !isEmpty(session) &&
                ["admin", "subscriber"].includes(session.role) ? (
                <Component {...props} />
              ) : (
                <Redirect to="/signin" />
              )}
            </Suspense>
          )}
        />
      );
    }
  );

  return (
    <BrowserRouter>
      <Switch>
        {/* Lazy load pages as needed. */}
        {publicRouteComponents}
        {adminRouteComponents}
        {userRouteComponents}
        <Route path="/*" exact component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
