import React from "react";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";

const CookiesDisabledError = () => {
  document.title = "Cookies are disabled - ReachShip";
  if (document.querySelector("meta[name='description']")) {
    document.querySelector("meta[name='description']").remove();
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10}>
        <Typography align="center" variant="h5">
          Cookies are disabled
        </Typography>
        <Typography align="center" variant="p">
          Your browser has cookies disabled. Make sure that your cookies are
          enabled and try again.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CookiesDisabledError;
